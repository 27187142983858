.overlay_own {
  position: absolute;
  top: 100%;
  left: 100%;
  width: 200px;
  height: 200px;
  display: none;
  opacity: 0;
  z-index: 99;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.thumbnail_own:hover .overlay_own {
  opacity: 1;
  display: block;
}
.hover-underline:hover {
  text-decoration: underline;
}

.truncate-ts,
.truncate-ts-type {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.truncate-ts:hover,
.truncate-ts-type:hover {
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
}
.truncate-ts-type:hover {
  overflow-x: scroll;
}
.btn-hover-credit:hover {
  color: #385972;
}

.blink_own {
  animation: blink_own 0.8s linear infinite;
}

@keyframes blink_own {
  50% {
    opacity: 0;
  }
}
